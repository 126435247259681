<template>
  <div >
    <div style="width: 480px; background: #fff; border-radius: 10px; box-shadow: 0px 0px 40px rgba(0,0,0,.1); border-radius: 10px; overflow: hidden; position: fixed; bottom: 110px; right: 30px;">
      <div  style="display: flex; align-items: center; justify-content: flex-end; background: #2563eb; padding: 8px 16px;">
        <div  style="width: 36px; height: 36px; border-radius: 100%; display: flex; align-items: center; justify-content: center; background: #0040c1; cursor: pointer;" @click="ChatShow = !ChatShow">
          <img style=" height: 12px;" src="@a/img/closeon.svg"/>
        </div>
      </div>
      <div style="display: flex; align-items: center;">
        <iframe :src="'/#/alRobot/chat/chat?code='+ code" width="100%" height="600"></iframe>
      </div>
    </div>

    <div
         style="user-select: none; cursor: pointer;width: 55px; height: 55px; border-radius: 100%; background: #2360e4; display: flex; align-items: center; justify-content: center; position: fixed; right: 30px; bottom: 30px; cursor: pointer;"
         @click="ChatShow = !ChatShow"
    >
      <img style="height: 21px;" v-show="!ChatShow" src="@a/img/logoon.png"/>
    </div>
  </div>
</template>


<script>

import {api} from "@/api-js";

export default {
  data() {
    return {
      ChatShow: false,
      code: '',
      title: '',
      subtitle: '',
      is_remove_brand: false,
      tileStyle: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#333'
      }

    };
  },
  components: {},
  methods: {},

  mounted() {
    this.start()
  }
}
</script>


<style>
@import "page.css";
</style>